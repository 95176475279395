import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { usePermissions } from 'contexts/permissions';
import { useProgram } from 'contexts/program';
import { ListPage } from 'DesignSystem/Layout/Pages';
import { useBoxFoldersInfiniteQuery } from 'hooks/box-knowledge-mangement';
import { SVGIcon as Icon } from 'shared/Icon/SVGIcon';
import { WithPermission } from 'shared/WithPermission';
import { BoxKMBanner } from './BoxFolderBanner';
import styles from './box.km.module.css';
import { getBoxConfigurationPageTabs } from './shared/tabs';
import { BOX_CONFIGURATION_PAGE_BREADCRUMBS } from './shared/breadcrumbs';
import {
  BOX_CONFIGURATION_PAGE_DESCRIPTION,
  BOX_CONFIGURATION_PAGE_TITLE,
} from './shared/header';

export const BoxManageFoldersList: React.FC<RouteComponentProps> = () => {
  const { permissions } = usePermissions();
  const { id: programId } = useProgram();
  const infiniteQuery = useBoxFoldersInfiniteQuery({
    programId,
  });

  return (
    <WithPermission
      permissions={['configureBoxKnowledgeManagementAccess']}
      operation="all"
    >
      <div className={styles.List}>
        <ListPage
          title={BOX_CONFIGURATION_PAGE_TITLE}
          description={BOX_CONFIGURATION_PAGE_DESCRIPTION}
          tabs={getBoxConfigurationPageTabs(permissions)}
          breadcrumbs={BOX_CONFIGURATION_PAGE_BREADCRUMBS}
          infiniteQuery={infiniteQuery}
          actions={[
            {
              icon: <Icon name="Plus" fill="currentColor" />,
              label: 'Folder',
              to: './folders/new',
            },
          ]}
          emptyList={
            <div>
              No folders yet. When you create a folder, you will see it here.
            </div>
          }
          renderRow={(item, index) => (
            <BoxKMBanner boxFolderData={item} index={index} />
          )}
        />
      </div>
    </WithPermission>
  );
};
