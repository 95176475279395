import { BoxItem } from '@socialchorus/box-components';
import { AxiosResponse } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import { deepCamelcaseKeys, request } from './api-shared';
import { Page } from './common';

export type FetchProps = {
  programId: number;
  page?: number;
  pageSize?: number;
};

export type BoxFolderData = {
  id: string;
  name: string;
  description: string;
  boxFolderId: number;
  audiences: {
    folderMappingId: string;
    audienceId: string;
    createdAt: string;
    updatedAt: string;
  }[];
  programId: number;
  folderCreatorId: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  boxFolderName: string;
};

const apiRoot = `${process.env.REACT_APP_BOSSANOVA_DOMAIN}`;

export async function fetchBoxFoldersPage({
  programId,
}: FetchProps): Promise<Page<BoxFolderData>> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders`;
  const response = await request(url);

  if (response.status === 200) {
    return response
      .json()
      .then((body) => {
        const result = camelcaseKeys(body, { deep: true });
        return { data: result };
      })
      .then(({ data, ...rest }) => {
        return {
          data,
          meta: {
            totalRecords: data.length,
            totalPages: 1,
            currentPage: 1,
            pageSize: data.length,
          },
          ...rest,
        };
      });
  }

  throw new Error(`Error fetching folders: ${response.status}`);
}

export type CreateBoxFolderData = Pick<
  BoxFolderData,
  'programId' | 'name' | 'description' | 'boxFolderId'
>;

export async function createBoxFolder(
  data: CreateBoxFolderData
): Promise<BoxFolderData> {
  const url = `${apiRoot}/v2/tenants/program:${data.programId}/box_integration/folders`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(snakeCaseKeys(data)),
  });

  if (response.status % 200 < 100) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  throw new Error(`Error creating folder: ${response.status}`);
}

export type UpdateFolderData = Pick<
  BoxFolderData,
  'programId' | 'name' | 'description' | 'boxFolderId'
>;

export async function updateBoxFolder(
  data: UpdateFolderData
): Promise<BoxFolderData> {
  const url = `${apiRoot}/v2/tenants/program:${data.programId}/box_integration/folders/${data.boxFolderId}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakeCaseKeys(data)),
  });

  if (response.status % 200 < 100) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  throw new Error(`Error updating folder: ${response.status}`);
}

export async function createBoxFolderAudienceMapping(
  programId: number,
  folderId: number,
  audienceId: string
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders/${folderId}/audiences/${audienceId}`;
  const response = await request(url, {
    method: 'POST',
  });

  if (response.status % 200 >= 100) {
    throw new Error(
      `Error creating folder-audience mapping: ${response.status}`
    );
  }
}

export async function deleteBoxFolderAudienceMapping(
  programId: number,
  folderId: number,
  audienceId: string
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders/${folderId}/audiences/${audienceId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status % 200 >= 100) {
    throw new Error(
      `Error deleting folder-audience mapping: ${response.status}`
    );
  }
}

export const deleteBoxMangementFolder = async (
  programId: number,
  folderId: number
): Promise<void> => {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders/${folderId}`;
  const response = await request(url, {
    method: 'DELETE',
  });

  if (response.status % 200 >= 100) {
    throw new Error(`Error deleting folder: ${response.status}`);
  }
};

export async function fetchById(
  programId: number,
  id: number
): Promise<BoxFolderData> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/folders/${id}`;
  const response = await request(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'x-requested-with': 'XMLHttpRequest',
    },
  });
  if (response.status === 200) {
    return response.json().then((output) => deepCamelcaseKeys(output));
  }
  throw new Error(`Error fetching box folder mapping: ${response.status}`);
}

export type FetchUserTokenResult = {
  programId: number;
  firstupUserId: number;
  boxUserId: number;
  token: string;
  tokenExpiresAt: string;
};

export async function fetchUserToken(
  programId: number
): Promise<FetchUserTokenResult> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/tokens`;
  const response = await request(url, {
    method: 'GET',
  });

  if (response.status % 200 < 100) {
    return response.json().then((output) => camelcaseKeys(output));
  }

  throw new Error(`Error fetching token: ${response.status}`);
}

export async function filterBoxResponseByPermission(
  programId: number,
  boxResponse: AxiosResponse<BoxItem>
): Promise<AxiosResponse<BoxItem>> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/permissions/filter`;
  const response = await request(url, {
    method: 'POST',
    body: JSON.stringify(boxResponse),
  });

  if (response.status % 200 < 100) {
    return response.json();
  }

  throw new Error(`Error filtering box response: ${response.status}`);
}

type BoxConfiguration = {
  boxAppSettings: {
    clientId: string;
    clientSecret: string;
    appAuth: {
      publicKeyId: string;
      privateKey: string;
      passphrase: string;
    };
  };
  enterpriseId: string;
};

export async function updateBoxConfiguration(
  programId: number,
  config: BoxConfiguration,
  clearMappings = true
): Promise<void> {
  const url = `${apiRoot}/v2/tenants/program:${programId}/box_integration/program_box_configs?clear_mappings=${clearMappings}`;
  const response = await request(url, {
    method: 'PUT',
    body: JSON.stringify(snakeCaseKeys(config)),
  });

  if (response.status % 200 >= 100) {
    throw new Error(`Error updating box configuration: ${response.status}`);
  }
}
